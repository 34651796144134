import React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import Styled from "./index.styles";

export type LogoType = "header" | "footer";

export default function Logo({ type }: { type: LogoType }) {
  const { t } = useTranslation(["containers"]);

  return (
    <Styled.logoContainer type={type}>
      <Link href="/">
        <a className={"logo"}>
          <h1>{t("containers:Layout.포워딩_쉽다")}</h1>
        </a>
      </Link>
    </Styled.logoContainer>
  );
}
