import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { YEAR_AS_MILLISECONDS } from "@sellernote/_shared/src/utils/common/date";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";

/**
 * i18n 기본 설정
 */
const defaultOptions = {
  returnNull: true,
  returnEmptyString: true,
  keySeparator: ".",
  nsSeparator: ":",
  saveMissing: false,
  saveMissingTo: "fallback",
  missingKeyHandler: undefined,
};

/**
 * i18n 키 보이기 설정
 */
const keyDisplayOptions = {
  returnNull: false,
  returnEmptyString: false,
  keySeparator: false,
  nsSeparator: false,
  saveMissing: true,
  saveMissingTo: "all",
  missingKeyHandler: (lng: readonly string[], ns: string, key: string) => key,
};

export default function TempLanguageButton() {
  const { isMobile } = useCheckIsMobile();

  const router = useRouter();

  const { pathname, query, asPath } = router;

  const { i18n } = useTranslation();

  const queryClient = useQueryClient();

  const [displaysKey, setDisplaysKey] = useState(false);

  const toggleI18n = () => {
    const newDisplaysKey = !displaysKey;

    setDisplaysKey(newDisplaysKey);

    /**
     * reload 시 상태가 초기화 되기 때문에 localStorage에 저장
     */
    localStorage.setItem("displaysKey", newDisplaysKey.toString());

    router.reload();
  };

  useEffect(() => {
    const savedDisplaysKey = localStorage.getItem("displaysKey") === "true";

    setDisplaysKey(savedDisplaysKey);

    if (savedDisplaysKey) {
      Object.assign(i18n.options, keyDisplayOptions);
      return;
    }

    Object.assign(i18n.options, defaultOptions);
  }, [i18n]);

  return (
    <div
      style={{ position: "absolute", left: isMobile ? 80 : 600, zIndex: 10 }}
    >
      <button onClick={toggleI18n}>key 표시 토글</button>

      <button
        onClick={() => {
          console.log("한국어");

          document.cookie = `NEXT_LOCALE=ko; path=/; max-age=${YEAR_AS_MILLISECONDS};`;

          router
            .push({ pathname, query }, asPath, { locale: "ko" })
            .then(() => {
              router.reload();
            })
            .then(() => {
              queryClient.invalidateQueries();
            });
        }}
      >
        '한국어'
      </button>
      <button
        onClick={() => {
          console.log("English");

          document.cookie = `NEXT_LOCALE=en; path=/; max-age=${YEAR_AS_MILLISECONDS};`;

          router
            .push({ pathname, query }, asPath, { locale: "en" })
            .then(() => {
              router.reload();
            })
            .then(() => {
              queryClient.invalidateQueries();
            });
        }}
      >
        'English'
      </button>
    </div>
  );
}
