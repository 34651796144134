import React from "react";
import { Trans, useTranslation } from "next-i18next";

import USER_QUERY from "@sellernote/_shared/src/queries/forwarding/USER_QUERY";
import { validateEmail } from "@sellernote/_shared/src/utils/common/validation";
import InputTextWithChips, {
  Chip,
} from "@sellernote/_sds-v2/src/components/form/InputTextWithChips";

import Styled from "./index.styles";

export default function InvitationTeam({
  inviteeList,
  setInviteeList,
}: {
  inviteeList: Chip[];
  setInviteeList: (inviteeList: Chip[]) => void;
}) {
  const { t } = useTranslation();

  const { data: userInfo } = USER_QUERY.useGetUserInfo({});

  const hasErrorInviteeItem = inviteeList.some(({ isError }) => isError);

  const hasUserEmail = inviteeList.some(
    ({ value }) => value === userInfo?.email
  );

  const validateUserEmail = (value: string) => value !== userInfo?.email;

  return (
    <Styled.container>
      <InputTextWithChips
        inputMode="email"
        labelInfo={{
          label: t("page-mypage-company-member:팀_생성_2단계_초대_입력_이메일"),
          isLabelHidden: true,
        }}
        chipDataList={inviteeList}
        setChipDataList={(chipDataList) => setInviteeList(chipDataList)}
        validate={(value) =>
          !validateEmail({ val: value, Trans: Trans }).result ||
          !validateUserEmail(value)
        }
        placeholder={t(
          "page-mypage-company-member:팀_생성_2단계_초대_입력_문구"
        )}
      />

      <Styled.guideContainer>
        <span className="guide">
          {t("page-mypage-company-member:팀_생성_2단계_이메일_기입_등록_안내")}
        </span>

        {(hasUserEmail || hasErrorInviteeItem) && (
          <span className="guide">
            {t(
              "page-mypage-company-member:팀_생성_2단계_이메일_기입_오류_안내"
            )}
          </span>
        )}
      </Styled.guideContainer>
    </Styled.container>
  );
}
