import React from "react";
import Router from "next/router";
import { Trans, useTranslation } from "next-i18next";

import TextButton from "@sellernote/_sds-v2/src/components/button/TextButton";

import Styled from "../index.styles";

export default function SalesManagerGuide({
  textColor,
  buttonColor,
  isBullet,
  isBrackets,
}: {
  textColor: string;
  buttonColor: string;
  isBullet?: boolean;
  isBrackets?: boolean;
}) {
  const { t } = useTranslation(["common-new", "containers"]);

  // TODO: TextButton 마이페이지 번역 확인 후 label 수정
  return (
    <Styled.salesMangerGuide
      {...{ textColor, buttonColor }}
      className="sales-manager-guide"
    >
      <span className="manager-desc">
        {isBullet && "*"}
        {isBrackets && "("}
        <Trans
          i18nKey="common-new:푸터_담당자별연락처_담당매니저문구"
          t={t}
          components={{
            lnk: (
              <TextButton
                label={t("containers:Layout.마이페이지")}
                fontSize={12}
                theme="white"
                handleClick={() => {
                  Router.push("/mypage");
                }}
                btnAreaPadding={{
                  top: 4,
                  bottom: 4,
                  right: 4,
                  left: 4,
                }}
              />
            ),
          }}
        />
        {isBrackets && ")"}
      </span>
    </Styled.salesMangerGuide>
  );
}
